/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": "https://x4bj3lqtvrc3xj2glnil3tii5m.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-w75xqwqljvgohaubihmosbwj6u",
    "aws_cognito_identity_pool_id": "eu-west-1:6d352c43-6bd7-4f0c-a6f9-0a18ddf37daf",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_bERFxSpjh",
    "aws_user_pools_web_client_id": "293d38ao2h5oabm9dedu2e2kuu",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "pmmwebapp-20220725120421-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "https://dx21dw7rh05kb.cloudfront.net"
};


export default awsmobile;
